import React from 'react';
import { Link, Image, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import qr from './img/qr.jpg';
import Logo from './img/Masken-Attest-5.jpg';

const styles = StyleSheet.create({
  page: {
    img: 'max-width:100%',
    backgroundColor: '#fff',
    border: '5px solid #ff0068'
  },
  section: {
    display: 'inline-block',
    margin: 10,
    padding: 10,
  },
  signature: {
    textAlign: 'right',
    margin: 10,
    bottom: 20
  },
  signature_img: {
    position: 'absolute',
    height: '70px',
    left:'60px',
    width: '140px'
  },
  qr: {
    height: 100,
    width: 100
  },
  logo: {
    marginLeft: 20,
    height: 70,
    width: 70
  },
  logo2: {
    marginTop: '-50px',
    height: 70,
    width: 70,
    marginRight: 20,
    marginLeft:'auto'
  },
  headline: {
    fontSize: 24,
    fontWeight: 'bold',
    textDecoration: 'underline',
    textAlign: 'center'
  },
  reason: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  link: {
    position: 'absolute',
    left:220,
    bottom: '10px'
  },
  datum: {
    fontSize: 12,
    marginRight: 10,
    textAlign: 'right'
  }
});

export default function Attest(props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View wrap={false} style={styles.section}>
          <Image src={Logo} style={styles.logo} /> 
          <Image src={props.tmpReason.org_img} style={styles.logo2} />
        </View>
        <View style={styles.datum}>
          <Text>{props.datum}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headline}>M a s k e n a t t e s t</Text>
        </View>
        <View style={styles.section}>
          <Text>Hiermit bestätigen wir, dass {props.blanko?' _______________________ ':props.name} berechtigt ist, überall und jederzeit einen Mund-Nase-Schutz zu tragen.{'\n\n'}Grund der Berechtigung:{'\n\n'}</Text>
          <Text style={styles.reason}>{props.reason}</Text>
        </View>
        <View style={styles.section}>
          <Text>{'\n'}Die Echtheit des Attestes ist jederzeit über folgenden QR-Code verifizierbar:</Text>
        </View>
        <View style={styles.section}>
          <Image debug={false} style={styles.qr} src={ qr } />
        </View>
        <View style={styles.section}>
          <Text>{props.tmpReason.outro}</Text>
        </View>
        <View style={styles.signature}>
          <Image style={styles.signature_img} src={props.tmpReason.signature_img}></Image>
        </View>
        <View style={styles.signature}>
          <Text>{props.tmpReason.signee}</Text> 
          <Text>{props.tmpReason.title}</Text>
        </View>
        <View style={styles.link}>
          <Link src="https://maskenattest.de">Maskenattest.de</Link>
        </View>
      </Page>
    </Document>
  )
}
