/* eslint-disable no-extend-native */
import './App.css';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { EmailShareButton, FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share';
import { EmailIcon, FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon } from 'react-share';
import PdfRender from './PdfRender';

function App() {
  const [name, setName] = useState('');
  const [reason, setReason] = useState([]);
  const [randomInUse, setRandomInUse] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rerenderToggle, setRerenderToggle] = useState(false);
  const [blanko, setBlanko] = useState(false);
  const [revealed, setRevealed] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const reasons = [ 
    {
      'name': 'Diskordianisch',
      'signature_img': require('./img/CupleWhil.jpg'),
      'signee': 'Cuple Whil',
      'title': 'Echte und authentische diskordische Mamst',
      'outro': 'Im Namen der Erisischen Bewegung! Kallisti!',
      'org_img': require('./img/hotdog.jpg'),
      'reasons': [
        'Alles muss bezweifelt werden!',
        'Mit der Maske wird das Gesetz der Fünf (2 Öhrli, 2 Äugli, ein Mäskli) erfüllt.',
        'Ein diskordischer Papst hat es befohlen.',
        'Ein diskordischer Papst hat es erlaubt.',
        'Ein diskordischer Papst hat es verboten.',
        'Jede Maske ist das geliebte Heim eines goldenen Virus.',
        'Eine Maske tarnt vor den Illuminaten.',
        'Hail Eris, all hail Discordia!',
        'Damit mich die Graugesichter nicht wiedererkennen.',
        '23',
        'Zum rituellen Hotdog darf ich sie ja abnehmen.'
      ]
    },
    {
      'name': 'Ästhetisch',
      'signature_img': require('./img/CupleWhil.jpg'),
      'signee': 'Cuple Whil',
      'title': 'Deutsches Topmodel',
      'outro': 'Für mehr Schönheit!',
      'org_img': require('./img/aesthetik.jpg'),
      'reasons': [
        'Meinen Gesichtselfmeter will ich anderen ersparen.',
        'Masken bringen meine Augen gut zur Geltung.',
        'Gesichtsmasken sind gut für die Haut.',
        'Freude feiner Filterfetzen',
        'Hab vorhin Spinat gegessen.'
      ]
    },
    {
      'name': 'Gesundheitlich',
      'signature_img': require('./img/CupleWhil.jpg'),
      'signee': 'Cuple Whil',
      'title': 'Wunderheilende/r i. R.',
      'outro': 'Bleiben Sie gesund!',
      'org_img': require('./img/gesundheit.jpg'),
      'reasons': [
        'Ich hatte seit über zwei Jahren keine Grippe. Merkwürdig, nicht? Denk da mal drüber nach!',
        'Ohne Maske wird aus Chi halt Hatschi...',
        'Maske schützt mich auch bei Heuschnupfen.',
        'Wenn man mal wieder die Dritten daheim vergisst.',
        'Mundgeruch ist kein Thema mehr für mich.',
        'Mein IQ-Test war positiv'
      ]
    },
    {
      'name': 'Katholisch',
      'signature_img': require('./img/CupleWhil.jpg'),
      'signee': 'Cuple Whil',
      'title': 'Was wichtiges im Vatikan',
      'outro': 'Ora et labora! (Vor allem LABORa!)',
      'org_img': require('./img/kreuz.jpg'),
      'reasons': [
        'Damit ich im Messdienerlager nicht erkannt werde.',
        'Manche verwechseln Colgate mit Golgata. Andere Maske mit Marter.',
        'Hinter jeder Maske ist immer ein lebendes Gesicht.',
        'Barmherzigkeit will ich nicht Opfer.',
        'Christ:innen sollen nicht nur, sondern müssen auf Virolog:innen und andere Mediziner:innen und Fachleute hören.',
        'Wir wollen unser Altgriechisch wieder haben.',
        'Für meine Kirchen-Mitgliedschaft muss ich mich ja auch nicht rechtfertigen.'
      ]
    },
    {
      'name': 'Islamisch',
      'signature_img': require('./img/CupleWhil.jpg'),
      'signee': 'Cuple Whil',
      'title': 'Ayatollah',
      'outro': 'Allahu Akbar!',
      'org_img': require('./img/muslim.jpg'),
      'reasons': [
        'O Allah, gib mir Gesundheit in meinem Körper, Gott gib mir Gesundheit in meinem Gehör, Gott gib mir Gesundheit in meinen Augen.',
        'Gepriesen sei Gott, der mir verziehen hat',
        'O Allah, ich suche Zuflucht bei Dir vor Aussatz, Wahnsinn und vor bösen Krankheiten.'
      ]
    },
    {
      'name': 'Solidarisch',
      'signature_img': require('./img/CupleWhil.jpg'),
      'signee': 'Cuple Whil',
      'title': 'Solidaritätsbeauftragter*in',
      'outro': 'Volker, hör die Signale!',
      'org_img': require('./img/solidaritaet.jpg'),
      'reasons': [
        'Ich bin einer der drei Masketiere.',
        'Mein Resting Bitch Face geht nur mich was an.',
        'Wo geht´s nur nächsten Demo',
        'Hinter jeder Maske steckt ein kluger Kopf.',
        'Damit mir keiner über den Mund fährt.',
        'Das Ahimsa-Prinzip untersagt das Töten und Verletzen von Lebewesen.',
        'Ich bin betucht.',
        'Die Gründe der Anderen'
      ]
    },
    {
      'name': 'Verschwörungstheoretisch',
      'signee': 'Cuple Whil',
      'signature_img': require('./img/CupleWhil.jpg'),
      'title': 'Unanerkannter deutscher Kaiser',
      'outro': 'Für Fahrer, Volker und Volkswagen!',
      'org_img': require('./img/chemtrail.jpg'),
      'reasons': [
        'Ich bin Mitglied einer geheimen Maskforce.',
        'Masken schützen vor Vögeln, Reptiloiden und runder Erde.',
        'Ich mache doch nicht einfach, was SIE erlauben! Schlafschaf!',
        'Ohne Maske darf ich nicht mehr auf die Youtube-Universität.',
        'Die Maske kann gut die einströmende Luft verschwurbeln.',
        'Deutschland einig Maskenland!',
        'WIR sind die Maskenträger!',
        'Mein Test heute war positiv.',
        'Das haben wir doch schon immer so gemacht.'
      ]
    },
    {
      'name': 'Kapitalistisch',
      'signee': 'Cuple Whil',
      'signature_img': require('./img/CupleWhil.jpg'),
      'title': 'Bankdirektorin',
      'outro': 'Für die Waagschale ohne Gegengewicht',
      'org_img': require('./img/kapital.jpg'),
      'reasons': [
        'Psst, ich bin Elon Mask.',
        'Ich muss gleich noch zur Bank, "Geld abheben".',
        'Bevor jemand im Fernsehen auftritt, muss er in die sogenannte Maske gehen.',
        'Die Maskenindustrie wird ja hauptsächlich von der NWO betrieben, habe ich gelesen.',
        'Die Maske fällt, es bleibt der Mensch; was heldisch war, sinkt hin.',
        'Goldene Nase? Pff, ich bin der Mann mit der goldenen Maske.',
        'Ausgesödert ist´s.',
        'Geben Sie die PIN Ihrer Kreditkarte bitte unten in die Eingabemaske.',
        'Corona-Lockerungsskandal - irgendwer verdient da jetzt wieder dran.'
      ]
    },
    {
      'name': 'Karnevalistisch',
      'signee': 'Cuple Whil',
      'signature_img': require('./img/CupleWhil.jpg'),
      'title': 'Vorsitzende des Paderborner Faschingsvereins',
      'outro': 'Alaaf, helau, helau, alaaf. Oder so.',
      'org_img': require('./img/karneval.jpg'),
      'reasons': [
        'Zorro, Zorro, Zorro!',
        'Ich trage Maske, wie Henry Maske boxt.',
        'Faßnachter bringen sich mit Masken in einen Ausnahmezustand jenseits ihrer sonstigen Regeln.',
        'Maskenball. Noch Fragen?',
        'Für meine Masked Singer-Facebookgruppe.',
        'Ey, was mas´kerade?',
        'Ich bin ein Maskottchen',
        'weil halt'
      ]
    },
    {
      'name': 'Anthroposophisch',
      'signee': 'Cuple Whil',
      'signature_img': require('./img/CupleWhil.jpg'),
      'title': 'Gluboli-Addict',
      'outro': 'Die Kunst ist ewig, ihre Formen wandeln sich',
      'org_img': require('./img/antro.jpg'),
      'reasons': [
        'Weil hier Stroh liegt.',
        'Masken sind schön abgerundet.',
        'An vielen Menschen ist nichts so echt wie ihre Maske.',
        'Eingabemasken sind schlimmer',
        ]
    },
    {
      'name': 'Freimaurerisch',
      'signee': 'Cuple Whil',
      'signature_img': require('./img/CupleWhil.jpg'),
      'title': 'Maurer',
      'outro': 'Quatuor Coronati',
      'org_img': require('./img/freimaurer.jpg'),
      'reasons': [
        '42',
        'Mittelmäßigkeit ist die glücklichste Maske, die der überlegene Geist tragen kann.',
        'Muskete + Machete = Maskete',
        ]
    },
    {
      'name': 'Protestantisch',
      'signee': 'Cuple Whil',
      'signature_img': require('./img/CupleWhil.jpg'),
      'title': 'Martin Luther König',
      'outro': 'Maske vertreibt den Tod.',
      'org_img': require('./img/kreuz.jpg'),
      'reasons': [
        'Christentum ist nicht zuallererst eine Frage von Moral, sondern von Verantwortung.',
        'Maske tragen ist Nächstenliebe.',
        'Derzeit ist das die angesagte Form Gesicht zu zeigen.',
        'Gerade wir Christinnen und Christen sollten hier mit gutem Beispiel vorangehen. Wir sind so frei und tragen Maske.',
        'Ist das christliche Freiheit, keine Maske zu tragen? Nein.'
        ]
    },
    {
      'name': 'Jüdisch',
      'signee': 'Cuple Whil',
      'signature_img': require('./img/CupleWhil.jpg'),
      'title': 'Rabbi',
      'outro': 'Schalom',
      'org_img': require('./img/leuchter.jpg'),
      'reasons': [
        'Die Kippa passt halt vorne nicht.',
        'Damit ich samstags unerkannt shoppen gehen kann.',
        'Sie müssen den Kafka nicht mögen. Oder so..',
        'Stolz ist die Maske der eigenen Fehler.',
        'Mose verhüllte sein Gesicht mit einem Tuch.',
        'Ich lebe masketisch',
        'Chuzpe!'
        ]
    }
  ];
  const [tmpReason, setTmpReason] = useState({});

  useEffect(() => {
    let tmp = [];
    reasons.forEach(tüp => { tüp.reasons.forEach(r => {
      tmp.push({reason: r, signee: tüp.signee, title: tüp.title, logo: tüp.logo});
    })});
    setCurrentIndex(Math.floor(Math.random() * tmp.length));
    setRandomInUse(tmp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerenderToggle]);

  function expand(e) {
    setTmpReason(reasons.filter(reason => reason.name === e.target.textContent)[0]);
  }
  function randomReason(tmp) {
    let r = Math.floor(Math.random() * randomInUse.length);
    setCurrentIndex(r);
    if (tmp)
      return tmp[r].reason;
    else
      return randomInUse[r].reason;
  }
  function newRandomReason() {
    let tmp = randomInUse;
    setRandomInUse(tmp);
    return randomReason(tmp);
  }
  function getCurrentRandomReason() {
    return randomInUse[currentIndex].reason;
  }
  function reveal() {
    setRevealed(true);
  }
  return (
    <div className="App">
      <ReactTooltip />
      <div className="block">
      <span id="random_reason">
        { !revealed && <button className="link red" onClick={() => reveal()}>Jetzt Grund für Attest generieren!</button> }
        { revealed && <div className="reason_display">{ randomInUse.length > 0 ? getCurrentRandomReason() : 'loading' }</div> }
        { !revealed && <div className="reason_display">Hier steht der Grund für mein Attest</div>}
        <button className="link red-inverse" onClick={() => randomInUse.length === 1 ? setRerenderToggle(!rerenderToggle) : newRandomReason()}>Erneut generieren</button>
        <button className="link red" onClick={(e) => { setReason(getCurrentRandomReason()); setTmpReason(reasons.filter(reason => reason.reasons.includes(getCurrentRandomReason()))[0]); }}>Den Grund will ich haben!</button>
        <br/>
        { !showShare && <button className={`link red ${revealed?'enabled':'disabled'}`} id="share_checkbox" onClick={() => setShowShare(!showShare)}>Teilen</button> }
        { showShare && <div className="share">
          <EmailShareButton url="https://maskenattest.de" subject="Mein Maskenattest!" body={`Mein endgültiger Grund für das Maskenattest: ${getCurrentRandomReason()}`}><EmailIcon borderRadius={10} round={false}/></EmailShareButton>
          <FacebookShareButton url="https://maskenattest.de" quote={`Mein endgültiger Grund für das Maskenattest: ${getCurrentRandomReason()}`} hashtag={'MitMaskenattest'}><FacebookIcon borderRadius={10} round={false}/></FacebookShareButton>
          <TwitterShareButton url="https://maskenattest.de" title={`Mein endgültiger Grund für das Maskenattest: ${getCurrentRandomReason()}`} hashtags={['MitMaskenattest']}><TwitterIcon borderRadius={10} round={false}/></TwitterShareButton>
          <TelegramShareButton url="https://maskenattest.de" title={`Mein endgültiger Grund für das Maskenattest: ${getCurrentRandomReason()}`}><TelegramIcon borderRadius={10} round={false}/></TelegramShareButton>
          <WhatsappShareButton url="https://maskenattest.de" title={`Mein endgültiger Grund für das Maskenattest: ${getCurrentRandomReason()}`} seperator={" | "}><WhatsappIcon borderRadius={10} round={false}></WhatsappIcon></WhatsappShareButton>
          </div>
        }
      </span>

      </div>
      <div className="block">
      <div className="flex" id="reasons">
      <h3>Oder wähle einen Grund aus unseren Kategorien aus</h3>
      <div className="flex">
      { reasons.map((anschauung, i) => {
        return (
          <>
            <button key={i} className={`link ${tmpReason.name === anschauung.name?'tuerkis-hi':'tuerkis' }`} onClick={(e) => expand(e)}>{anschauung.name}</button>
          </>
        )
      })}
      </div>
      { (Object.keys(tmpReason).length > 0) && <hr/> }
      <div className="flex">
        { (Object.keys(tmpReason).length > 0) && tmpReason?.reasons?.map((re,i) => {
          return (<button key={i} className={`link ${reason === re ? 'blau-hi':'blau'} `} onClick={(e) => setReason(e.target.textContent)}>{re}</button>)
        })}
        </div>
      </div>
      </div>
      <div className="block">
      <div className="flex" id="attest"> 
        <h3 data-tip='Dein Name wird von uns nicht gespeichert, sondern nur für die Generierung des Dokuments verwendet!'>Name auf dem Attest *</h3>
        <br/>
        <input type="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} /><br/>
        <label><input type="checkbox" id="blanko" onChange={(e) => setBlanko(e.target.checked)} /><span data-tip='Druckt eine leere Linie auf das Attest, sodass du deinen Name selber eintragen kannst.'>Blanko *</span></label>
      </div>
      </div>

      <div id="output" className={`block ${(reason[0] && (name !== '' || blanko === true) && tmpReason.length !== 0) ? 'enabled' : 'disabled'}`}> 
        <PdfRender tmpReason={tmpReason} reason={reason} name={name} blanko={blanko} />
      </div>
    </div>
  );
}

export default App;
