import { PDFDownloadLink } from '@react-pdf/renderer';
import Attest from './Attest';


var seasons = [
    "Chaos", "Discord", "Confusion",
    "Bureaucracy", "The Aftermath"
    ];
    var weekday = [
    "Sweetmorn", "Boomtime", "Pungenday",
    "Prickle-Prickle", "Setting Orange"
    ];
    
    var apostle = [
    "Mungday", "Mojoday", "Syaday",
    "Zaraday", "Maladay"
    ];
    
    var holiday = [
    "Chaoflux", "Discoflux", "Confuflux",
    "Bureflux", "Afflux"
    ];
    
    
    Date.prototype.isLeapYear = function() {
    var year = this.getFullYear();
    if( (year & 3) !== 0 ) { return false; }
    return ((year % 100) !== 0 || (year % 400) === 0);
    };
    
    // Get Day of Year
    Date.prototype.getDOY = function() {
    var dayCount  = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
    var mn        = this.getMonth();
    var dn        = this.getDate();
    var dayOfYear = dayCount[mn] + dn;
    if( mn > 1 && this.isLeapYear() ) { dayOfYear++; }
    return dayOfYear;
    };
    
    Date.prototype.isToday = function() {
    var today = new Date();
    return this.getDate()     === today.getDate()
    && this.getMonth()    === today.getMonth()
    && this.getFullYear() === today.getFullYear()
    ;
    };
    
    function discordianDate(date) {
    if( !date ) { date = new Date(); }
    
    var y                = date.getFullYear();
    var yold             = y + 1166;
    var dayOfYear        = date.getDOY();
    var celebrateHoliday = null;
    
    if( date.isLeapYear() ) {
    if( dayOfYear === 60 ) {
    celebrateHoliday = "St. Tib's Day";
    }
    else if( dayOfYear > 60 ) {
    dayOfYear--;
    }
    }
    dayOfYear--;
    
    var divDay = Math.floor(dayOfYear / 73);
    
    var seasonDay = (dayOfYear % 73) + 1;
    if( seasonDay == 5 ) {
    celebrateHoliday = apostle[divDay];
    }
    if( seasonDay == 50 ) {
    celebrateHoliday = holiday[divDay];
    }
    
    var season    = seasons[divDay];
    var dayOfWeek = weekday[dayOfYear % 5];
    
    var nth = (seasonDay % 10 === 1) ? 'st'
    : (seasonDay % 10 === 2) ? 'nd'
    : (seasonDay % 10 === 3) ? 'rd'
                      : 'th';
    
    return "" //(date.isToday() ? "Today is " : '')
    + dayOfWeek
    + ", the " + seasonDay + nth
    + " day of " + season
    + " in the YOLD " + yold
    + (celebrateHoliday ? ". Celebrate " + celebrateHoliday + "!" : '')
    ;
    }
export default function PdfRender({tmpReason, blanko, name, reason}) {
    let output = '';
    if (reason[0] && (name !== '' || blanko === true) && tmpReason.length !== 0) {
        output = <PDFDownloadLink
            className="link red"
            key={0}
            document={<Attest
            datum={(tmpReason.name === 'Diskordianisch') ? discordianDate(new Date()) : new Date().toLocaleDateString('de-DE', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            blanko={blanko}
            name={name}
            reason={reason}
            tmpReason={tmpReason}/>}
            fileName="attest.pdf" target="_blank">
            {({ blob, url, loading, error }) =>
            loading ? 'Lade Attest...' : 'Herunterladen!'
            }
        </PDFDownloadLink>
    }
    return (
        <>
            {output}
        </>
    )
}